import React from "react";
import { Box } from "@mui/material";
import HourglassPaths from "./HourglassPaths";

const Hourglass = ({ originalHours, hourBalance }) => {
  const usedHours = Math.max(0, originalHours - hourBalance).toFixed(2);
  const getRoundedPercentage = (originalHours, usedHours) => {
    if (originalHours === 0) return 0;
    if (usedHours >= originalHours) return 0;
    if (usedHours == 0) return 100;

    const percentageUsed = (usedHours / originalHours) * 100;
    if (percentageUsed <= 1) return 1;
    if (percentageUsed >= 99) return 99;

    const roundedValues = [1, 10, 20, 30, 40, 50, 60, 70, 80, 90, 99];
    return roundedValues.reduce((prev, curr) =>
      Math.abs(curr - percentageUsed) < Math.abs(prev - percentageUsed)
        ? curr
        : prev
    );
  };

  const remainingPercentage = getRoundedPercentage(originalHours, usedHours);
  console.log(
    { originalHours, usedHours, remainingPercentage, hourBalance },
    originalHours - hourBalance
  );
  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <HourglassPaths
        progress={remainingPercentage}
        availableHours={hourBalance}
        usedHours={usedHours}
      />
    </Box>
  );
};

export default Hourglass;
