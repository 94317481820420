import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Container,
} from "@mui/material";
import ClientApi from "../services/ClientApi";
import Hourglass from "./Hourglass";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

const Dashboard = () => {
  const navigate = useNavigate();
  const [companyName, setCompanyName] = useState("Your Company Name");
  const [hourBalance, setHourBalance] = useState(0);
  const [pendingHours, setPendingHours] = useState(0);
  const [tasks, setTasks] = useState([]);
  const [error, setError] = useState("");
  const [recentActivities, setRecentActivities] = useState([]);
  const [recentPackageHours, setRecentPackageHours] = useState(0);
  const renderLines = 3;
  const [loading, setLoading] = useState(true);
  const handlePurchaseHours = () => navigate("/packages");
  const handleSeeMoreActivities = () => navigate("/activities");
  const handleViewAllTasks = () => navigate("/tasks");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseActivities = await ClientApi.get("activities"); // Adjust the API endpoint based on your backend routes
        const { company, allActivities: timeActivities } =
          responseActivities.data;
        const responseOpenTasks = await ClientApi.get("tasks");
        const { tasks } = responseOpenTasks.data;
        setTasks(tasks.sort((a, b) => (a.timeStamp > b.timeStamp ? -1 : 1)));
        setCompanyName(company.companyName);
        var approvedTimeBal = timeActivities
          .filter((ta) => ta.status === "completed")
          .reduce((a, b) => a + b.hours, 0);
        var pendingHours = timeActivities
          .filter(
            (ta) => ta.activityType === "TimeAdd" && ta.status === "pending"
          )
          .reduce((a, b) => a + b.hours, 0);
        setHourBalance(approvedTimeBal.toFixed(2));
        setPendingHours(pendingHours.toFixed(2));
        setRecentActivities(
          timeActivities
            .filter((ta) => ta.status === "completed")
            .sort((a, b) => (a.timeStamp > b.timeStamp ? -1 : 1))
            .slice(0, renderLines)
        );
        const mostRecentTimeAdd = timeActivities
          .filter(
            (activity) =>
              activity.activityType === "TimeAdd" &&
              activity.status === "completed"
          )
          .reduce(
            (mostRecent, current) =>
              !mostRecent ||
              new Date(current.timeStamp) > new Date(mostRecent.timeStamp)
                ? current
                : mostRecent,
            null
          );
        setRecentPackageHours(mostRecentTimeAdd?.hours || 0);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
        setError("Error getting data from the server.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <Box sx={{ padding: 3, bgcolor: "#f4f6f8", minHeight: "100vh" }}>
      {error && (
        <Typography color="error" align="center" marginBottom="30px">
          {error}
        </Typography>
      )}

      {/* Header */}
      <Typography variant="h4" gutterBottom>
        <strong>{companyName} - Dashboard</strong>
      </Typography>
      <Typography variant="subtitle1" color="text.secondary">
        Overview of your hours, tasks, and activities.
      </Typography>

      {loading ? (
        <Container>
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        </Container>
      ) : (
        <Grid container spacing={3}>
          {/* Hour Balance: Spans 2 columns */}
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom justifySelf="center">
                  Hour Balance
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 250,
                  }}
                >
                  <Hourglass
                    originalHours={recentPackageHours}
                    hourBalance={hourBalance}
                    // originalHours={45}
                    // hourBalance={-48}
                  />
                </Box>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  justifySelf="center"
                  sx={{ mt: 0.45 }}
                >
                  {`Recent Purchase: ${recentPackageHours} Hours`}
                </Typography>
                {pendingHours > 0 && (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    justifySelf="center"
                  >
                    {pendingHours} hours pending activation
                  </Typography>
                )}
                <Box sx={{ textAlign: "center", mt: 2 }}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<ShoppingCartIcon />}
                    onClick={handlePurchaseHours}
                  >
                    Purchase More Hours
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Open Tasks Section */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    Open Tasks ({tasks.length})
                  </Typography>
                </Box>
                <List>
                  {tasks.length > 0 ? (
                    tasks.slice(0, renderLines).map((task) => (
                      <ListItem key={task.id}>
                        <ListItemText
                          primary={task.title}
                          secondary={task.description}
                        />
                      </ListItem>
                    ))
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      No open tasks.
                    </Typography>
                  )}
                </List>
                <Box sx={{ textAlign: "center", mt: 2 }}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleViewAllTasks}
                  >
                    View All Tasks
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Activity Overview */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Recent Activities
                </Typography>
                <List>
                  {recentActivities.length > 0 ? (
                    recentActivities.map((activity) => (
                      <ListItem key={activity.id}>
                        <ListItemText
                          primary={`${
                            activity.description
                          }${` (${activity.hours} hours)`}`}
                          secondary={`Date: ${new Date(
                            activity.timeStamp
                          ).toDateString()}`}
                        />
                      </ListItem>
                    ))
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      No recent activity.
                    </Typography>
                  )}
                </List>
                <Box sx={{ textAlign: "center", mt: 2 }}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleSeeMoreActivities}
                  >
                    View All Activities
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Dashboard;
