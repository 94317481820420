import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Alert,
  AlertTitle,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
} from "@mui/material";
// import FormControlLabel from '@mui/material/FormControlLabel';
import { Edit, Delete, History } from "@mui/icons-material";
import RefreshIcon from "@mui/icons-material/Refresh";
import TaskIcon from "@mui/icons-material/Task";
import AddIcon from "@mui/icons-material/Add";
import ClientApi from "../services/ClientApi";

function Tasks() {
  const cleanFormState = {
    _id: "",
    company: "",
    // timeStamp: new Date(),
    title: "",
    description: "",
    priority: 10,
  };
  const [data, setData] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteTaskId, setDeleteTaskId] = useState(null);
  const [form, setForm] = useState(cleanFormState);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [selectedTaskHistory, setSelectedTaskHistory] = useState([]);
  const [openHistoryModal, setOpenHistoryModal] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [dataLoading, setDataLoading] = useState(true);
  const [emailClient, setEmailClient] = useState(true);

  useEffect(() => {
    // Fetch companies from your data source
    const fetchCompanies = async () => {
      const response = await ClientApi.get("/superadmin/companies"); // Replace with your API endpoint
      setCompanies(response.data);
    };

    fetchCompanies();

    // Fetch time logs from your data source
    const fetchTasks = async () => {
      const response = await ClientApi.get("/superadmin/allopentasks"); // Replace with your API endpoint
      setData(response.data);
      setDataLoading(false);
    };

    fetchTasks();
  }, [refresh]);

  const handleOpenConfirmDialog = (task, type) => {
    setForm(task);
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
    setForm(cleanFormState);
  };

  const handleConfirmSetDone = async () => {
    console.log("Marking task as Done");
    await ClientApi.put(
      `/superadmin/tasks/markdone/${form._id}?emailClient=${emailClient}`
    )
      .then(() => {
        setData((prev) => prev.filter((item) => item._id !== form._id));
        setOpenConfirmDialog(false);
        setForm(cleanFormState);
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(
          err.response?.data?.message ??
            err.response?.message ??
            err.message ??
            err
        );
      });
  };

  const handleHistoryClick = (taskHistory) => {
    setSelectedTaskHistory(taskHistory);
    setOpenHistoryModal(true);
  };

  const handleCloseHistoryModal = () => {
    setOpenHistoryModal(false);
    setSelectedTaskHistory([]);
  };

  const handleDeleteModalOpen = (taskId) => {
    setDeleteTaskId(taskId);
    setIsDeleteModalOpen(true);
  };
  const handleDeleteModalClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setIsDeleteModalOpen(false);
    setDeleteTaskId(null);
    setErrorMessage(null);
  };

  const handleClickOpen = (item) => {
    if (item) {
      setForm(item);
    } else {
      setForm({
        _id: "",
        company: filter || "",
        // timeStamp: new Date(),
        title: "",
        description: "",
        priority: 10,
      });
    }
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
    setErrorMessage(null);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  //   const handleDateChange = (date) => {
  //     setForm((prev) => ({
  //       ...prev,
  //       timeStamp: date,
  //     }));
  //   };

  const handleSave = () => {
    if (form._id) {
      ClientApi.put(`/superadmin/tasks/${form._id}`, form)
        .then((response) => {
          setData((prev) =>
            prev.map((item) => (item._id === form._id ? response.data : item))
          );
          handleClose();
        })
        .catch((err) => {
          console.log(err);
          setErrorMessage(
            err.response?.data?.message ??
              err.response?.message ??
              err.message ??
              err
          );
        });
    } else {
      // Create new item
      ClientApi.post("/superadmin/tasks", form)
        .then((response) => {
          setData((prev) => [...prev, response.data]);
          handleClose();
        })
        .catch((err) => {
          console.log(err);
          setErrorMessage(
            err.response?.data?.message ??
              err.response?.message ??
              err.message ??
              err
          );
        });
    }
  };

  const handleDelete = () => {
    ClientApi.delete(`/superadmin/tasks/${deleteTaskId}`)
      .then(() => {
        setData((prev) => prev.filter((item) => item._id !== deleteTaskId));
        handleDeleteModalClose();
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(
          err.response?.data?.message ??
            err.response?.message ??
            err.message ??
            err
        );
      });
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredData = data.filter(
    (row) =>
      (!filter || row.company === filter) &&
      (row.description.toLowerCase().includes(search.toLowerCase()) ||
        row.title.toString().includes(search))
  );

  return (
    <Container>
      <Box my={3}>
        <Typography variant="h3" align="center">
          Open Tasks
        </Typography>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <IconButton
          onClick={() => handleClickOpen()}
          color="primary"
          aria-label="add"
        >
          <AddIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            setDataLoading(true);
            setRefresh(refresh + 1);
          }}
          color="primary"
          aria-label="refresh"
        >
          <RefreshIcon />
        </IconButton>
      </Box>
      <TextField
        label="Search"
        value={search}
        onChange={handleSearchChange}
        fullWidth
        margin="normal"
      />
      <TextField
        select
        label="Filter by Company"
        value={filter}
        onChange={handleFilterChange}
        fullWidth
        margin="normal"
      >
        <MenuItem value="">All</MenuItem>
        {companies.map((company) => (
          <MenuItem key={company._id} value={company._id}>
            {company.companyName}
          </MenuItem>
        ))}
      </TextField>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>Company</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Title</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Description</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Priority</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ whiteSpace: "pre-wrap" }}>
            {dataLoading && (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
            {!dataLoading &&
              filteredData
                .sort((a, b) => a.priority - b.priority)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row._id}>
                    <TableCell>
                      {
                        companies.find((c) => c._id === row.company)
                          ?.companyName
                      }
                    </TableCell>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>{row.priority}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleClickOpen(row)}>
                        <Edit />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDeleteModalOpen(row._id)}
                      >
                        <Delete />
                      </IconButton>
                      <IconButton onClick={() => handleOpenConfirmDialog(row)}>
                        <TaskIcon />
                      </IconButton>
                      {row.history?.length > 0 && (
                        <IconButton
                          onClick={() => handleHistoryClick(row.history)}
                        >
                          <History />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={filteredData.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog open={openHistoryModal} onClose={handleCloseHistoryModal}>
        <DialogTitle>Task History</DialogTitle>
        <DialogContent>
          {selectedTaskHistory.length ? (
            <List>
              {selectedTaskHistory.map((historyItem) => (
                <ListItem key={historyItem._id}>
                  <ListItemText
                    primary={
                      <>
                        <Typography variant="body1" component="span">
                          <strong>Changed Field:</strong>{" "}
                          {historyItem.changedField}
                        </Typography>
                        <Typography variant="body2" component="div">
                          <strong>Old Value:</strong> {historyItem.oldValue}
                        </Typography>
                        <Typography variant="body2" component="div">
                          <strong>New Value:</strong> {historyItem.newValue}
                        </Typography>
                        <Typography variant="body2" component="div">
                          <strong>Date:</strong>{" "}
                          {new Date(historyItem.creationDate).toLocaleString()}
                        </Typography>
                        <Typography variant="body2" component="div">
                          <strong>By:</strong>{" "}
                          {`${historyItem.creationUser.firstName} ${historyItem.creationUser.lastName}`}
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <p>No history available for this task.</p>
          )}
        </DialogContent>
      </Dialog>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{form._id ? "Edit Task" : "Add Task"}</DialogTitle>
        <DialogContent>
          <TextField
            label="ID"
            name="_id"
            value={form._id}
            onChange={handleChange}
            fullWidth
            disabled={true}
            margin="normal"
          />
          <TextField
            select
            label="Company"
            name="company"
            value={form.company}
            disabled={!!form._id}
            onChange={handleChange}
            fullWidth
            margin="normal"
          >
            {companies.map((company) => (
              <MenuItem key={company._id} value={company._id}>
                {company.companyName}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Title"
            name="title"
            value={form.title}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Description"
            name="description"
            multiline
            value={form.description}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Priority"
            name="priority"
            type="number"
            value={form.priority}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        {errorMessage && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        )}
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isDeleteModalOpen} onClose={handleDeleteModalClose}>
        <DialogTitle>Are you sure you want to DELETE this Task?</DialogTitle>
        {errorMessage && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        )}
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDeleteModalClose}
          >
            Cancel
          </Button>
          <Button variant="contained" color="error" onClick={handleDelete}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog}>
        <DialogTitle>{"Confirm Marking Task as Done"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to mark this Task as DONE?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FormControlLabel
            control={
              <Checkbox
                name="closeTask"
                checked={emailClient}
                onChange={(t) => setEmailClient(t.target.checked)}
                fullWidth
                margin="normal"
              />
            }
            label="Email Client"
          />
          <Button
            onClick={handleCloseConfirmDialog}
            variant="contained"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmSetDone}
            variant="contained"
            color="secondary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Tasks;
